import React from 'react'

// Components
import ParseContent from 'components/shared/ParseContent'
import ButtonPrimary from 'components/elements/Buttons/ButtonPrimary'

// Third Party
import styled from 'styled-components'

interface BannerMoreInformationProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_FlexHome_Banner
}

const Icon = styled.img`
  height: 210px;
  width: 870px;

  @media screen and (max-width: 991px) {
    height: 200px;
  }
`

const Row = styled.div`
  height: 100%;

  @media screen and (max-width: 991px) {
    background-color: ${({ theme }) => theme.color.light};
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
    margin: 50px 0;
    z-index: 2;
    height: fit-content;
    width: 100%;
    padding: 10px;

    & h3 {
      font-size: 20px;
      line-height: 24px;
    }
  }

  @media screen and (max-width: 575px) {
    text-align: center;
  }
`

const BannerMoreInformation: React.FC<BannerMoreInformationProps> = ({
  fields,
}) => (
  <section>
    <div className="container">
      <div className="row">
        <div className="col-lg-8 position-relative">
          <Row className="row py-3">
            <div className="pb-sm-0 pb-4 col-sm-5 col-lg-6 d-flex justify-content-sm-start justify-content-center align-items-center">
              <ParseContent content={fields.description || ''} />
            </div>
            <div className="col-sm-7 col-lg-6 d-flex align-items-center justify-content-center">
              <ButtonPrimary to={fields.link?.url || '/'}>
                <div className="d-flex align-items-center">
                  {fields.link?.title}
                </div>
              </ButtonPrimary>
            </div>
          </Row>
        </div>

        <div className="col-lg-4 d-lg-block d-none">
          <Icon src={fields.icon?.localFile?.publicURL} alt="" />
        </div>
      </div>
    </div>
  </section>
)

export default BannerMoreInformation
