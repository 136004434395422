import React from 'react'

// Components
import BannerMoreInformation from 'components/flex/Banner/BannerMoreInformation'

interface BannerShellProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.Maybe<GatsbyTypes.WpPage_Flexcontent_Extrafooter>
  location?: any
}

interface PostProps {
  [key: string]: any
}

const BannerShell: React.FC<BannerShellProps> = ({ fields, location = {} }) => {
  const types: PostProps = {
    moreinformation: BannerMoreInformation,
  }

  if (!fields?.styletype || !types[fields.styletype]) {
    return null
  }

  const Component = types[fields.styletype]

  return (
    <Component
      key={`${location ? location.pathname : 'flex'}`}
      fields={fields}
      location={location}
    />
  )
}

export default BannerShell
