import React from 'react'
import styled, { css } from 'styled-components'
import ButtonShell from 'components/elements/Buttons/ButtonShell'

// Images
import Arrow from 'img/arrow.inline.svg'

export const ButtonDarkCSS = css`
  background-color: ${(props) => props.theme.color.dark};
  display: inline-block;

  & > a,
  & > button {
    color: ${(props) => props.theme.color.light} !important;
    font-weight: ${({ theme }) => theme.font.weight.bold};
    padding: 10px 15px;
    width: 100%;
    height: 100%;
    display: block;
    text-decoration: none !important;
  }
`

const StyledButton = styled.span`
  ${ButtonDarkCSS};
`

const InnerShell = styled.div`
  & svg {
    margin-left: 30px;
    & path {
      fill: ${(props) => props.theme.color.light} !important;
    }
  }
`

interface ButtonDarkProps {
  isAnchor?: boolean
  isCustom?: boolean
  to: string
  className?: string
  target?: string
  arrow?: boolean
}

const ButtonDark: React.FC<ButtonDarkProps> = ({
  isAnchor = false,
  isCustom = false,
  to,
  children,
  className = '',
  target = '',
  arrow = true,
}) => (
  <StyledButton className={className}>
    {isCustom ? (
      children
    ) : (
      <ButtonShell to={to} isAnchor={isAnchor} target={target}>
        <InnerShell className="d-flex align-items-center">
          {children}
          {arrow && <Arrow />}
        </InnerShell>
      </ButtonShell>
    )}
  </StyledButton>
)

export default ButtonDark
